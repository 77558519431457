@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.container {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: flex-start;
  padding: 0.25rem 0.2rem 0.25rem 1.563rem;
  align-items: center;
  color: $color-grey;

  .detailsContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-transform: capitalize;
  }

  &.withItems {
    align-items: center;
    justify-content: space-between;
  }

  &.activeSubItem {
    color: $primary-main;

    svg {
      color: $primary-light !important;
      height: 0.5rem !important;
      width: 0.5rem !important;
    }
  }

  .navigationIcon {
    margin-right: 0.625rem;
  }
}
