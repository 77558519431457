@import "../../styles/variables";
@import "../../styles/mixins";

.navigationContainer {
  display: flex;
  align-items: center;
  color: $primary-main;
  padding: 20px 18px;
  right: 0;
  z-index: 8;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px);
  justify-content: space-between;
  box-shadow: rgba(34, 51, 84, 0.2) 0 2px 8px -3px, rgba(34, 51, 84, 0.1) 0px 5px 22px -4px;
  width: 100%;
  position: sticky;
  top: 0;

  @include bp (m) {
    height: 80px;
    left: 290px;
    padding: 0 18px;
    position: fixed;
    width: calc(100% - 290px);
  }

  .iconItems {
    display: flex;
    align-items: center;
    gap: 16px;

    button {
      color: $primary-main;
    }
  }
}

.popoverContent {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  border-radius: 8px;
  background-image: none;
  overflow: hidden auto;
  min-width: 200px;
  min-height: 16px;
  max-height: calc(100% - 32px);
  outline: 0;
  box-shadow: rgba(145, 158, 171, 0.24) 0 0 2px 0, rgba(145, 158, 171, 0.24) 0 12px 24px 0;
  max-width: 100%;
  opacity: 1;
  transform: none;
  transition: opacity 230ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 153ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .loginAs {
    color: $primary-main;
    padding: 12px 14px 10px;
    border-bottom: 1px solid $background-color;
    font-family: $globalFont;
    font-size: 16px;
    font-weight: 500;
  }

  .popoverList {
    list-style: none;
    margin: 0;
    padding: 8px 0;
    position: relative;
    outline: 0;

    .popoverListItem {
      list-style: none;
      background-color: transparent;
      outline: 0;
      border: 0;
      margin: 0;
      border-radius: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      line-height: 1.5;
      font-size: 1rem;
      font-family: $globalFont;
      font-weight: 300;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      text-decoration: none;
      padding: 6px 16px;
      box-sizing: border-box;
      white-space: nowrap;
      color: rgb(99, 115, 129);

      &:hover {
        background-color: rgb(244, 246, 248);
      }

      &.disabled {
        cursor: not-allowed;
        color: rgba(99, 115, 129, 0.5);

        &:hover {
          background-color: transparent;
        }
      }

      &.active {
        background-color: $background-color;
      }

      .popoverIcon {
        flex-shrink: 0;
        display: inline-flex;
        color: inherit;
        margin-right: 16px;
      }

      .popoverItemText {
        flex: 1 1 auto;
        min-width: 0;
      }
    }
  }
}