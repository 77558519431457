@import '../../../styles/variables';
@import '../../../styles/mixins';

.menuItems {
  list-style: none;
  margin: 0;
  position: relative;
  padding: 0.5625rem;
  text-transform: capitalize;

  .menuItemsTitle {
    box-sizing: border-box;
    list-style: none;
    font-family: $globalFont;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7rem;
    color: $black-color;
    padding: 0 1.4063rem;
    line-height: 1.4;
    cursor: pointer;
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover,
    &:focus {
      color: $primary-main;
      border-radius: 0.625rem;
    }
  }

  .menuSubItemsContainer {
    overflow: hidden;
    position: relative;
    height: auto;
    opacity: 1;
    transition: height 0.3s ease-in-out, top 0.3s ease-in-out,
      opacity 0.3s ease-in-out;

    &.isClosed {
      top: -100%;
      height: 0;
      opacity: 0;
    }

    .menuSubItems {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 0.563rem;
      list-style: none;
      margin: 0;
      position: relative;
      transition: max-height 0.3s ease-in-out;

      .subItemContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        padding: 0.063rem 0;
        text-transform: capitalize;

        .subSubItemContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          align-items: center;
          transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          top: -100%;
          height: 0;
          opacity: 0;

          &.isActive {
            height: auto;
            opacity: 1;
            transition: height 0.3s ease-in-out, top 0.3s ease-in-out,
              opacity 0.3s ease-in-out;
          }
        }

        .subItem {
          display: flex;
          gap: 1rem;
          color: $color-grey;
          width: 100%;
          justify-content: flex-start;
          padding: 0.875rem 1.6875rem;
          align-items: center;
          position: relative;
          box-sizing: border-box;
          outline: 0;
          border: 0;
          cursor: pointer;
          user-select: none;
          vertical-align: middle;
          appearance: none;
          text-decoration: none;
          font-family: $globalFont;
          font-size: 0.875rem;
          line-height: 1.75;
          min-width: 4rem;
          border-radius: 0.625rem;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          font-weight: 500;
          text-transform: none;
          margin: 0 0 0.25rem;

          .detailsContainer {
            display: flex;
            align-items: center;
            gap: 1rem;
            text-transform: capitalize;
          }

          &.withItems {
            align-items: center;
            justify-content: space-between;
          }

          &:hover {
            background-color: $active-blue-lighter;
            border-radius: 0.625rem;
          }

          &.activeSubItem {
            background-color: $active-blue;
            color: $primary-main;
            font-weight: 600;
            border-radius: 0.625rem;
            text-transform: capitalize;
          }

          .navigationIcon {
            margin-right: 0.625rem;
          }
        }
      }
    }
  }
}
