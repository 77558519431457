@import '../../styles/variables';
@import '../../styles/mixins';

.textField {
  width: 100%;

  :global(.MuiAutocomplete-inputRoot) {
    padding-right: 9px !important;
  }

  &.currencyInput {
    p {
      margin: 1px 0 0;
      line-height: 1.5;
      font-family: $globalFont;
      font-weight: 400;
      color: rgb(99, 115, 129);

      &:global(.Mui-error) {
        color: $input-error-text;
        margin: 8px 14px 0;
        font-size: 0.75rem;
        line-height: 1.66;
      }
    }
  }

  &.timeInput {
    :global(.MuiFormHelperText-sizeMedium) {
      position: absolute;
      top: 50px;
    }
  }

  label {
    font-family: $globalFont;
    color: $label;
    font-size: 14px;
    line-height: 1.4374em;
    font-weight: 400;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;

    &:global(.Mui-focused) {
      color: $label-focused;
    }

    &:global(.Mui-error) {
      color: $label-error;
    }
  }

  > div {
    border-radius: 10px;
    font-size: 14px;
    font-family: $globalFont;
    font-weight: 400;
    line-height: 1.4375;

    input {
      font-family: $globalFont;
    }

    fieldset {
      border-color: $input-border;

      legend {
        width: auto;
      }
    }

    &:global(.Mui-focused) {
      fieldset {
        border-color: $input-border-focused !important;
      }
    }

    &:global(.Mui-error) {
      fieldset {
        border-color: $input-border-error !important;
      }
    }
  }

  p {
    font-family: $globalFont;
    margin: 8px 14px 0;

    &:global(.Mui-error) {
      color: $input-error-text;
    }
  }
}

.fileField {
  background-color: $bright-color;
  transition: padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  text-align: left;
  overflow: hidden;
  display: flex;
  outline: none;

  @include bp(s) {
    flex-direction: row;
  }

  input {
    display: none;
  }

  .fileContainer {
    width: 100%;
    height: 130px;

    @include bp(s) {
      width: 160px;
    }

    .fileSelectedContainer {
      border: 1px dashed $color-grey-light;
      border-radius: 10px;
      padding: 10px;
      height: 100%;

      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }

      .innerFileSelectContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        height: 100%;
        background: rgba(218, 222, 227, 0.5);
        color: $color-grey;

        .innerFileIcon {
          color: $color-grey;

          &.errorFile {
            color: $red-color;
            opacity: 0.8;
          }

          &.successFile {
            color: $primary-main;
            opacity: 0.8;
          }
        }

        .innerFileSelectLabel {
          margin-top: 12px;
          font-family: $globalFont;
          font-size: 11px;
          font-weight: 400;
          color: $color-grey;
        }
      }
    }
  }

  .label {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;

    @include bp(s) {
      padding: 24px;
    }

    h5 {
      margin: 0 0 8px;
      font-weight: 700;
      line-height: 1.5;
      font-size: 14px;
      font-family: $globalFont;
    }

    p {
      margin: 0;
      line-height: 1.57143;
      font-size: 12px;
      font-family: $globalFont;
      font-weight: 400;
      color: rgb(99, 115, 129);

      &.selectedFileLabel {
        margin-top: 20px;
        margin-bottom: 6px;
        color: $color-grey;
        font-size: 11px;

        .selectedFileIcon {
          color: $color-grey;
          margin-right: 6px;
        }
      }
    }

    .fileError {
      font-family: $globalFont;
      font-weight: 400;
      font-size: 10px;
      color: $red-color;
    }
  }
}

.switcherContainer {
  margin-top: 10px;

  :global(.MuiSwitch-switchBase) {
    color: $red-color;
  }

  :global(.Mui-checked) {
    color: rgb(0, 171, 85) !important;
  }

  :global(.Mui-checked + .MuiSwitch-track) {
    background-color: rgb(0, 171, 85) !important;
  }

  :global(.MuiSwitch-track) {
    background-color: $red-color;
  }

  :global(.MuiFormControlLabel-label) {
    line-height: 1.57143;
    font-size: 14px;
    font-family: $globalFont;
    font-weight: 400;
  }
}

.recaptcha {
  transform: scale(0.9);
  text-align: center;
  display: flex;
  justify-content: center;

  @include bp(xxs) {
    transform: scale(1);
  }
}

.repatchaError {
  font-family: $globalFont;
  font-weight: 400;
  font-size: 0.75rem;
  color: $red-color;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin: -16px 14px 0;
}

.autocomplete {
  .showMoreChip {
    cursor: pointer;
  }
}
