@import "../../styles/mixins";
@import "../../styles/variables";

.alert {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: $globalFont;
  font-weight: 400;
  border-radius: 8px;
  display: flex;
  padding: 6px 12px;

  &.danger {
    color: $error-text;
    background-color: $error-background;

    .icon {
      color: $error-icon;
    }
  }

  &.warning {
    color: rgb(122, 79, 1);
    background-color: rgb(255, 247, 205);

    .icon {
      color: rgb(255, 193, 7);
    }
  }

  &.info {
    color: rgb(4, 41, 122);
    background-color: rgb(208, 242, 255);

    .icon {
      color: rgb(24, 144, 255);
    }
  }

  &.success {
    color: rgb(8, 102, 13);
    background-color:rgb(233, 252, 212);

    .icon {
      color: rgb(84, 214, 44);
    }
  }

  .icon {
    margin-right: 12px;
    padding: 7px 0;
    display: flex;
    font-size: 22px;
    opacity: 0.9;
  }

  .message {
    padding: 8px 0;
  }
}
