@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.subItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  text-transform: capitalize !important;
  letter-spacing: normal;

  :global(.MuiButton-root) {
    text-transform: capitalize;
  }

  .subSubItemContainer {
    padding-top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: -100%;
    height: 0;
    opacity: 0;

    &.isActive {
      padding-top: 0.5rem;
      height: auto;
      opacity: 1;
      transition: height 0.3s ease-in-out, top 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
    }
  }

  .subItem {
    display: flex;
    gap: 1rem;
    color: $color-grey;
    width: 100%;
    justify-content: flex-start;
    padding: 0.875rem 1.6875rem;
    align-items: center;
    position: relative;

    .detailsContainer {
      display: flex;
      align-items: center;
      gap: 1rem;
      text-transform: capitalize;
    }

    &.withItems {
      align-items: center;
      justify-content: space-between;
    }

    &.activeSubItem {
      background-color: rgba(25, 118, 210, 0.06);
      color: $primary-main;
      font-weight: 600;
      border-radius: 0.625rem;
    }

    .navigationIcon {
      margin-right: 0.625rem;
    }
  }
}
